.repeatingLines4 {
  width: 65.313rem;
  height: 108rem;
  position: relative;
  background-color: var(--color-silver);
  display: none;
  opacity: 0.4;
  max-width: 100%;
  z-index: 0;
}

/* .topSectionContainer {
  position: relative;
  z-index: 1;
} */

.signUpLogin {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  margin-left: auto;
}

/* .logoIcon {
  width: 100px;
  height: 100px;
} */

.header {
  width: 100%;
  display: flex;
}

.meetYourDna {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: 3.5rem;
  line-height: 3.5rem;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
  z-index: 3;
}

.largeBackgroundLogoIcon,
.repeatingLines2Icon,
.repeatingLines3Icon {
  position: absolute;
  top: -0.687rem;
  left: -6.25rem;
  width: 73.5rem;
  height: 108rem;
  object-fit: cover;
}

.largeBackgroundLogoIcon,
.repeatingLines3Icon {
  left: -4.375rem;
  width: 73.875rem;
  z-index: 1;
}

.largeBackgroundLogoIcon {
  left: 6.319rem;
  width: 85.119rem;
  z-index: 9999;
}

.buttonBackground {
  position: absolute;
  top: 24.5rem;
  left: 23.75rem;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-200);
  width: 42.5rem;
  height: 20.125rem;
  z-index: 5;
}

.repeatingLines2Parent {
  height: 107.313rem;
  width: 90rem;
  position: absolute;
  margin: 0 !important;
  right: -11.312rem;
  bottom: -90.687rem;
}

.introBig {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
}

.introText {
  margin: 0;
  text-align: center;
  min-height: 2.5rem;
  white-space: normal;
  display: inline-block;
  width: 100%;
}

.introTextBody {
  flex: 1;
  position: relative;
  line-height: 1.563rem;
  display: inline-block;
  max-width: 100%;
  z-index: 3;
  text-align: center;
}

.introTextBodyContainer,
.introBigContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  margin-top: 1.5rem;
}

.introTextBodyContainer {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-133xl);
  box-sizing: border-box;
  font-size: var(--p1-size);
  font-family: var(--p2);
  min-height: 4.5rem;
}

.introBigContainer {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-mid);
}

.customButtonWrapper,
.sidekickContentWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  box-sizing: border-box;
  max-width: 100%;
}

.customButtonWrapper {
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  width: fit-content;
  padding: 0;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 1400px;
  margin: auto;
  width: 90%;
  min-width: 300px;
  margin-top: var(--padding-6xl);
  height: fit-content;
}

.sidekickContentWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  box-sizing: border-box;
  max-width: 100%;
  margin: auto;
  width: 67.688rem;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

/* .customButtonWrapper {
  padding: 0 var(--padding-449xl);
} */

.frameChild {
  height: 4.156rem;
  width: 4.313rem;
  position: relative;
}

.benefitSpacer {
  align-self: stretch;
  height: 1.656rem;
  position: relative;
  border-radius: 0 0 var(--br-xl) var(--br-xl);
  background-color: var(--gradient-full);
}

.broomIcon {
  height: 4.156rem;
  width: 3.956rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.backgroundImage,
.broomContainer {
  position: absolute;
  display: flex;
  justify-content: flex-start;
}

.examplesWithIcons {
  margin-top: 2rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2.25rem 2.75rem;
  z-index: 3;
  text-align: left;
  font-size: var(--h3-size);
  justify-content: center;
  padding: 0 0 12rem;
}

.backgroundImage {
  top: -0.687rem;
  width: 97.688rem;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-22xl) var(--padding-183xl) var(--padding-935xl);
  box-sizing: border-box;
  gap: var(--gap-102xl);
}

.span {
  color: var(--pink);
}

/* .yesYouCan {
  color: var(--color);
} */
/* .canYouKickContainer {
  margin: 0;
  height: 2.75rem;
  flex: 1;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
} */
/* .dividerText {
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--black);
  width: 43.125rem;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xl) var(--padding-16xl);
  box-sizing: border-box;
  max-width: 100%;
  z-index: 5;
  font-size: var(--h2-size);
  color: var(--gradient-full);
} */
.backgroundImageParent {
  align-self: stretch;
  height: 100%;
  position: relative;
  max-width: 100%;
}

.splashStatementText {
  flex: 1;
  position: relative;
  line-height: 1.563rem;
  display: inline-block;
  max-width: 100%;
  z-index: 5;
}

.frameParent,
.splashStatement {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}

.splashStatement {
  width: 87.725rem;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-290xl);
  font-size: var(--p1-size);
  font-family: var(--p2);
}

.frameParent {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-xl-6);
  text-align: center;
  font-size: var(--h1-size);
  color: var(--black);
  font-family: var(--h2);
  min-height: 100vh;
  height: -webkit-fill-available;
  height: stretch;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}

@media screen and (max-width: 1400px) {
  .splashStatement {
    padding-left: var(--padding-135xl);
    padding-right: var(--padding-135xl);
    box-sizing: border-box;
  }

  .frameParent {
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1200px) {
  .frameParent {
    box-sizing: border-box;
  }
}

@media screen and (max-width: 825px) {
  .meetYourDna {
    font-size: var(--font-size-29xl);
    line-height: 3.25rem;
  }

  .createPlansAutomatically {
    font-size: var(--font-size-7xl);
  }

  .canYouKickContainer {
    font-size: var(--font-size-16xl);
  }

  .splashStatement {
    padding-left: var(--padding-58xl);
    padding-right: var(--padding-58xl);
    box-sizing: border-box;
  }

  .frameParent {
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .meetYourDna {
    font-size: var(--font-size-17xl);
    line-height: 2.438rem;
  }

  .createPlansAutomatically {
    font-size: var(--font-size-lgi);
  }

  .canYouKickContainer {
    font-size: var(--font-size-7xl);
  }

  .splashStatement {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}

.videoSection {
  padding: 0 5%;
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videoPlaceholder {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
}

.heroImagevideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 14px 7px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xl);
  background-size: cover;
  background-position: center;
  z-index: 4;
  border: 2px solid white;
  transition: opacity 0.4s ease-in-out, background-image 0.3s ease-in-out;
}

.iconPlayCircle {
  display: none;
}

.backgroundContainer {
  position: absolute;
  right: 0;
  height: 100%;
  width: fit-content;
  top: 0;
  z-index: 1;
  /* Lower this value to be below the logo */
}

.backgroundContainer img {
  position: relative;
  height: 110%;
  float: right;
}

@media screen and (max-width: 768px) {
  .logoIcon {
    width: 120px;
    height: 120px;
    z-index: 9999;
    padding-left: 10px;
    margin-right: 10px;
  }

  .mobileButton {
    font-size: 0.75rem;
    padding: 4px 10px !important;
    height: 2.25rem !important;
  }

  .signUpLogin {
    gap: 0.5rem;
  }

  .header {
    align-items: center;
  }

  .videoSection {
    padding: 0 3%;
    max-width: 100%;
  }

  .customButtonWrapper {
    padding: 0;
    width: fit-content;
  }

  .introTextBodyContainer {
    padding: 0 5%;
    /* Adjust this value as needed */
  }

  .sidekickContentWrapper {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .videoPlaceholder {
    padding-top: 56.25%;
  }

  .frameParent {
    height: auto !important;
    min-height: auto;
  }

  .headerWrapper {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .meetYourDna {
    font-size: var(--font-size-17xl);
    line-height: 2.438rem;
  }

  .createPlansAutomatically {
    font-size: var(--font-size-lgi);
  }

  .canYouKickContainer {
    font-size: var(--font-size-7xl);
  }

  .splashStatement {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }

  .sidekickContentWrapper {
    margin-top: 1.5rem;
  }

  .videoPlaceholder {
    padding-top: 56.25%;
  }

  .customButtonWrapper {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .introTextBodyContainer {
    min-height: 5rem;
  }
}

/* Special fix for iOS Safari on small devices */
@media screen and (max-width: 380px) and (-webkit-min-device-pixel-ratio: 0) {
  .sidekickContentWrapper {
    margin-top: 1rem;
  }

  .meetYourDna {
    font-size: 2rem !important;
    line-height: 2rem !important;
  }

  .introTextBodyContainer {
    min-height: 3.5rem;
  }

  .typingText,
  .cursor {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .videoPlaceholder {
    padding-top: 56.25%;
  }

  .customButtonWrapper {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}

.logoIcon {
  z-index: 10000;
  /* Increase this value to be higher than the background */
}

.dividerText {
  position: absolute;
  border-radius: var(--br-xl);
  background-color: var(--black);
  bottom: -50px;
  width: 90%;
  max-width: 43.125rem;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xl) var(--padding-16xl);
  box-sizing: border-box;
  z-index: 10;
  font-size: var(--h2-size);
  color: var(--gradient-full);
  left: 0;
  right: 0;
  margin: auto;
}

.coloredLinesFixed {
  transform: translate(-800px);
  position: absolute;
  z-index: 4;
}

.coloredLinesFixed img {
  transform: rotate(-15deg);
  width: 3000px;
}

.canYouKickContainer {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
  text-align: center;
}

.yesYouCan {
  color: var(--color);
}

.span {
  color: var(--pink);
}

@media screen and (max-width: 768px) {
  .dividerText {
    bottom: -22.5px;
    padding: var(--padding-3xl) var(--padding-8xl);
    height: 50px;
  }

  .canYouKickContainer {
    font-size: 1.5rem;
    line-height: 1.2;
    margin: auto;
  }
}

@media screen and (max-width: 480px) {
  .dividerText {
    bottom: -25px;
    padding: var(--padding-2xl) var(--padding-4xl);
  }

  .canYouKickContainer {
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
  }

  .header {
    margin-left: -25px;
  }

  .sidekickContentWrapper {
    margin-top: 1.5rem;
  }
}

/* @media screen and (max-width: 825px) {
  .header {
    width: 95%;
  }
} */

/* Remove video-specific styles */
.heroVideo {
  display: none;
}

/* Skip navigation for keyboard users */
.skipNav {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background: white;
  text-decoration: none;
}

.skipNav:focus {
  left: 50%;
  transform: translateX(-50%);
}

/* Ensure proper contrast for text elements */
.introTextBody {
  color: #000000; /* Ensure sufficient contrast */
}

/* Add visible focus indicators for interactive elements */
.customButtonWrapper button:focus {
  outline: 3px solid #4a90e2;
  outline-offset: 2px;
}

/* Add new media query for mobile Safari */
@supports (-webkit-touch-callout: none) {
  .frameParent {
    height: auto !important;
    min-height: 100vh;
  }
}

.topSection_videoKeyword {
  display: none;
}

@keyframes fadeInOut {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.9;
  }
}

.heroImagevideo {
  animation: fadeInOut 3s infinite;
}

@media screen and (max-width: 768px) {
  .meetYourDna {
    font-size: 3rem;
    line-height: 2.75rem;
  }

  .topSection_videoKeyword {
    font-size: 1.125rem;
    margin-top: 0.75rem;
  }
}

@media screen and (max-width: 480px) {
  .meetYourDna {
    font-size: 2.5rem;
    line-height: 2.25rem;
  }

  .topSection_videoKeyword {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}

/* Transition class with more subtle opacity change */
.transitioning {
  opacity: 0.75;
}

/* Add the typing animation CSS */
.typingText {
  display: inline;
  font-size: 1.75rem;
  line-height: 2.1rem;
  white-space: normal;
}

.cursor {
  display: inline;
  margin-left: 3px;
  font-weight: bold;
  color: #000;
  font-size: 1.75rem;
  position: relative;
  top: -0.125rem;
}

.cursorBlink {
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* Remove the now-unused keyword text style */
.topSection_videoKeyword {
  display: none;
}

/* Ensure the intro text is properly styled */
.introText {
  margin: 0;
  text-align: center;
  min-height: 2.5rem;
  white-space: normal;
  display: inline-block;
  width: 100%;
}

/* Make sure the typing area has enough space */
.introTextBodyContainer {
  min-height: 4.5rem;
  margin-top: 1.5rem;
}
